import Image from 'next/image'
import { useRouter } from 'next/router';
import React from 'react'

export default function Header() {
    const router = useRouter();
    return (
        <div style={{
            backgroundColor: "#f8f9fa",
            padding: "35px 25px 15px 25px",
            display: "flex",
            justifyContent: "space-between",
        }}>
            <Image
                src="/images/logo.svg"
                alt="logo-1"
                width="76"
                height="45"
                onClick={() => {
                    router.push("/");
                }}
                style={{
                    marginTop: "-15px"
                }}
            />
            <Image
                src="/images/brain-fit-logo.png"
                alt="logo-2"
                width="75"
                height="45"
                style={{
                    marginTop: "-15px"
                }}
            />
        </div>
    )
}
