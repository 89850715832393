import "bootstrap/dist/css/bootstrap.min.css";
import 'sweetalert2/src/sweetalert2.scss'
import "@/styles/globals.css";
import type { AppProps } from "next/app";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { useRouter } from "next/router";
import Head from "next/head";

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const pathname = router.pathname;

  const calculateCurrentThaiMonth = () => {
    const thaiMonthList = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ]
    const date = new Date();
    const month = date.getMonth();
    return thaiMonthList[month];
  }

  return <>
    <Head>
      <meta charSet="utf-8" />
      <title>PTT Debenture Brain Fit</title>
      <meta
        content={`เชิญชวนสมาชิก PTT Debenture Club เข้าร่วมกิจกรรมเดือน ${calculateCurrentThaiMonth()}`}
        name="description"
      />
      <meta content="PTT Debenture Brain Fit" property="og:title" />
      <meta
        content={`เชิญชวนสมาชิก PTT Debenture Club เข้าร่วมกิจกรรมเดือน ${calculateCurrentThaiMonth()}`}
        property="og:description"
      />
      <meta
        content="/images/metatag.jpg"
        property="og:image"
      />
      <meta content="PTT Debenture Brain Fit" property="twitter:title" />
      <meta
        content={`เชิญชวนสมาชิก PTT Debenture Club เข้าร่วมกิจกรรมเดือน ${calculateCurrentThaiMonth()}`}
        property="twitter:description"
      />
      <meta
        content="/images/metatag.jpg"
        property="twitter:image"
      />
      <meta property="og:type" content="website" />
      <meta content="summary_large_image" name="twitter:card" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
    </Head>
    <Header />
    <div className="container">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-12 col-md-8">
          <Component {...pageProps} />
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
    {
      pathname === "/rule" && (
        <Footer />
      )
    }
  </>;
}
