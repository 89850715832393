import React from 'react'

export default function Footer() {
    return (
        <div
            style={{
                backgroundColor: "#181562",
                padding: "25px 15px 20px 15px",
                marginTop: "15px",
            }}
        >
            <p className='detail text-white'>
                <b>สอบถามรายละเอียดเพิ่มเติม :</b><br />
                Tel. PTT Debenture Contact Center (02) 624-5599 (08.00-18.00 น.) ไม่เว้นวันหยุด
                <br />Email: debentureclub@pttplc.com
            </p>
            <p className='detail text-white'>
                <b>ดำเนินการโดย :</b><br />
                ฝ่ายผู้ลงทุนสัมพันธ์ บริษัท ปตท. จำกัด (มหาชน)
                <br />555 ถนนวิภาวดีรังสิต แขวงจตุจักร เขตจตุจักร กรุงเทพฯ 10900 โดย มอบหมายการดำเนินกิจกรรมภายใต้การดูแลของบริษัท นิวเมอร์ลิน จำกัด
            </p>
            <div style={{ padding: '35px' }}></div>
        </div>
    )
}
